<template>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-3"></div>

      <div class="col-md-6 register-container">
        <div class="card register">
          <div class="card-body">
            <p class="card-title pb-4">{{ $t('views.forms.registeration_success_heading') }}</p>
            <p class="card-text white-text">{{ $t('views.forms.registeration_success_content') }}</p>
            <div class="mb-5">
              <div v-if="submitted" class="red-text m-2 error-mssg">{{ errors.first("Position") }}</div>
            </div>
             <button
              class="btn btn-block mt-4"
              @click="goToLogin()"
            >{{ $t('views.forms.login_now') }}</button>
          </div>
        </div>
      </div>

      <div class="col-md-3"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Register',
  data() {
    return {
      user: {
        first_name: '',
        last_name: '',
        company: '',
        email: '',
        password: '',
        // Added those hard coded as there are not yet implemented in our front end
        company_position: '',
        inviter_id: '',
        accept_terms: false,
        recaptcha_token: ''
      },
      status: '',
      siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      firmaName: '',
      submitted: false,
      type: 'password',
      eyeClass: 'fa fa-fw fa-eye field-icon toggle-password'
    };
  },
  methods: {
    togglePassword() {
      if (this.eyeClass.includes('fa-eye-slash')) {
        this.eyeClass = this.eyeClass.replace('fa-eye-slash', 'fa-eye');
        document.getElementById('input-pwd').type = 'password';
      } else {
        this.eyeClass = this.eyeClass.replace('fa-eye', 'fa-eye-slash');
        document.getElementById('input-pwd').type = 'text';
      }
    },
    validateForm() {
      this.submitted = true;
      this.$validator.validate().then(valid => {
        if (valid) {
          this.$refs.recaptcha.execute();
        }
      });
    },
    async register() {
      const form = new FormData();
      form.append('token', this.$route.query.t);
      try {
        let response = await this.$http.post('/verify-email', form);
        if (response.status == 200) {
          console.log('success');
        }
      } catch (e) {
        console.log(e);
      }
    },
    onCaptchaVerified(recaptchaToken) {
      this.status = 'submitting';
      this.$refs.recaptcha.reset();
      this.user.recaptcha_token = recaptchaToken;
      this.register();
      this.status = '';
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
    goToLogin() {
      this.$router.push('/login');
    }
  },
  created() {
    this.firmaName = this.$route.query.inviter || 'Hafele';
    this.user.inviter_id = this.$route.query.inviter_id || '4';
  },
  async mounted() {
    let recaptchaScript = document.createElement('script');
    recaptchaScript.setAttribute(
      'src',
      'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit'
    );
    recaptchaScript.setAttribute('async', '');
    recaptchaScript.setAttribute('defer', '');
    document.head.appendChild(recaptchaScript);
    await this.register();
  }
};
</script>

<style lang="scss" scoped>
header {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  z-index: -1;
}

header p {
  text-transform: none;
  margin: 0;
  font-size: 14rem;
  opacity: 0.5;
  text-align: left;
  font-weight: 350;
  line-height: 0.5;
  letter-spacing: -5.7px;
  color: #ffffff;
}

.footer {
  margin-top: 10px !important;
  margin-bottom: 0p !important;
  font-size: 10px;
  color: white;
  font-weight: 300;
}

.error-mssg {
  font-size: 12px;
}

.toggle-password:hover {
  cursor: pointer;
}

.card {
  border-radius: 0;
}

.card-body {
  padding: 2rem 3.5rem;
}

.container {
  margin-top: $loginMarginTop;
}

.register {
  background-color: rgba(80, 80, 80, 0.9);
  margin-bottom: 40px !important;
}

.card-title {
  font-weight: 300;
  color: $secondaryColor;
  font-size: 42px;
  padding-bottom: 10px !important;
}

.card-text {
  font-size: 16px !important;
  font-weight: 400;
}

input {
  font-size: 16px !important;
}

.register .text-field {
  border-radius: 0%;
  background-color: rgba(80, 80, 80, 0.9);
  color: white;
  border-color: rgba(255, 255, 255, 0.7);
}

.register ::placeholder {
  color: white;
  opacity: 0.5;
}

.field-icon {
  float: right;
  margin-right: 10px;
  margin-top: -35px;
  position: relative;
  z-index: 2;
  color: white;
  opacity: 0.8;
}

.checkbox-text {
  font-size: 16px;
}

.checkbox-text span {
  font-weight: 700;
}

.register .btn {
  color: black;
  background-color: white;
  height: 37px;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.15px;
  padding-left: 7%;
  padding-top: 1.5%;
  text-transform: capitalize;
  margin-top: 1.5rem !important;
  margin-bottom: 1rem;
  border-radius: 0 !important;
}

.register .btn:hover {
  background-color: $secondaryColor;
}

@media screen and (max-device-width: 640px) and (orientation: landscape) {
  header {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
}

@media screen and (max-device-width: 480px) and (orientation: portrait) {
  header {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }

  .card-text {
    font-size: 10px !important;
  }

  input {
    font-size: 10px !important;
  }

  .register .btn {
    font-size: 10px !important;
    padding-left: 15px !important;
    padding-top: 10px !important;
  }

  .checkbox-text {
    font-size: 10px !important;
  }

  p {
    font-size: 14px !important;
  }
}

@media screen and (max-device-width: 1024px) {
  header {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
}
</style>
